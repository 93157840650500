@import url(https://cdn.jsdelivr.net/npm/pretty-checkbox@3.0/dist/pretty-checkbox.min.css);
@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://cdn.jsdelivr.net/npm/pretty-checkbox@3.0/dist/pretty-checkbox.min.css);
@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://cdn.jsdelivr.net/npm/pretty-checkbox@3.0/dist/pretty-checkbox.min.css);
@import url(https://fonts.googleapis.com/css?family=Varela+Round);
/* Import Pretty Checkboxes*/
/* Import Varela Round from Google Fonts*/

/*Variables defined*/
:root {
  --main-color: coral;
  --text-color: #444;
  --formcolor: white;
  --button-color: hsl(356, 90%, 64%);
}
.container-q {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.main-q {
  width: 550px;
  background-color: rgba(148, 143, 171, 0.3);
  border-radius: 10px;
  color: white;
  margin-left: calc(50% - 275px);
}

.name {
  text-align: center;
  margin: 1rem;
  overflow-y: hidden;
}

.contact-form {
  display: block;
  border-radius: 12px;
  color: white;
  text-align: center;
  overflow-y: hidden;
}
.title {
  border-bottom: 4px solid;
  font-size: 3rem;
  padding: 1rem;
  margin-bottom: 15px;
  color: white;
  overflow-y: hidden;
  text-align: center;
}

.text {
  padding: 0.5rem;
  padding-top: 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.input-field {
  background: transparent;
  color: white;
  padding: 5px;
  font-size: 1.1rem;
  width: 60%;
  border-radius: 10px;
  border: 1px solid gray;
  margin-bottom: 1rem;
}
.op {
  background-color: rgba(0, 0, 0, 0.5);
}


.sub {
  background-color: transparent;
  border: 1.5px solid red;
  width: 60%;
  color: white;
  padding: 0.5rem;
  margin: 0rem;
  border-radius: 15px;
  transition: 0.5s;
  margin: 1rem;
}
.sub:hover {
  background-color: red;
  color: white;
}
.req {
  color: red;
  font-size: 1.1rem;
  padding-left: 0.5rem;
}

.required {
  color: white;
  font-size: 0.9rem;
  text-align: right;
  margin: 1rem;
  margin-right: 20%;
}

.keyword {
  margin: 1rem 0rem;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 10px;
  width: 60%;
}

.opt {
  text-align: center;
  color: white;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0.5rem;
}
#i-radio {
  padding-left: 1rem;
  padding-right: 0.5rem;
}
.opt-check {
  font-size: 1.1rem;
}

.check-radio {
  margin: 1.5rem;
}
@media only screen and (max-width: 1300px) {
  .wrapper {
    justify-content: space-evenly;
  }
  .main-q {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 600px) {
  .main-q {
    width: 400px;
    margin-left: calc(50% - 200px);
  }
  .title {
    font-size: 2rem;
  }
  .input-field,
  .sub {
    width: 80%;
  }
}
@media only screen and (max-width: 450px) {
  .main-q {
    width: 350px;
    margin-left: calc(50% - 175px);
  }
  .title {
    font-size: 1.8rem;
  }
}

/*.front {
  
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: var(--text-color);
  font-family: 'Varela Round', sans-serif; 
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
  position: "absolute";
  display: flex;
  flex-direction: column;
  align-items: center;
  










  form {

        
        display: block;
        border-radius: 12px;
        padding: 5vh 10vw;
        color: white;
        text-align: center;
        overflow-y: hidden;
        
        
        
            .title {
                padding: 10px 0px;
                padding-left: 5px;
                border-bottom: 4px solid;
                font-size: 3rem;
                margin-bottom: 15px;
                color: white;
            }
@media only screen and (max-width: 700px) {
.title{
  font-size: 2.5rem;
}
}

@media only screen and (max-width: 520px) {
  .title{
    font-size: 2rem;
  }
  }
  
  @media only screen and (max-width: 420px) {
    .title{
      font-size: 1.6rem;
    }
    }
              .outer{

              display: flex;
              padding-left: calc(50% - 300px);


            .array{
              width:50%;
              padding: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              
              
              .logo{
                width: 150px;
                height: 150px;
                background-image: url('../assets/alpha3new.png');
                background-position: center;
                background-size: 100% 100%;

              }
              

              
              
              
              
            }
            .ips{

              width: 100%;
              text-align: center;

              
              
              .signup-field {
                
                padding: 10px;
                padding-left: 5px;
                text-align: left;
                color: black;


                input{
                  height: 38px;
                  width: 300px;
                  
                }
                

                .text{
                  align-items: center;
                  color: white;
                  font-size: 1.5rem;
                  text-align: center;
                  
                }
                @media only screen and (max-width: 520px) {
                  .text{
                    font-size: 1.2rem;
                  }
                  }
                  @media only screen and (max-width: 420px) {
                    .text{
                      font-size: 1rem;
                    }
                  }

            }

            
            
            .input-field {
                border: 0;
                width: 300px;
                border-radius: 5px;
                height: 2.5rem;
                background-color: #f0f0f0;
                color: black;
                margin-left: calc(50% - 150px);

                .op{
                    font-size: 1.2rem;
                    color: black;
                    border-radius: 20px;
                    padding-left: 20px;
                }
            }
            @media only screen and (max-width: 420px) {
              .input-field{
                width: 260px;
                margin-left: calc(50% - 130px);

                .op{

                  font-size: 0.8rem;
                }
              }
            }

            
            label {
                display: block;
                font-size: 23px;
                margin-bottom: 8px;
                color: white;
            }

            
        }
      }
      

                input[type="submit"] {
                font-family: inherit;
                font-size: 1.3rem;
                font-weight: 600;
                margin-top: 1rem;
                padding: 0.8rem 1.2rem;
                border: 2px outset var(--button-color);
                border-radius: 10px;
                cursor: pointer;
                color: white;
                background-color: transparent;
                transition: all 0.2s ease-out;
            }

            input[type="submit"]:hover {
                cursor: pointer;
                background-color: var(--button-color, black);
                color: var(--formcolor);
                transition-duration: 0.4s;
            }
            
            
    }
}

@media only screen and (max-width: 1300px) {
  .wrapper{
    justify-content: space-evenly;
  }
     
}

*/



.bm-burger-button{position:fixed;width:36px;height:30px;right:36px;top:36px}.bm-burger-bars{background:#fff}.bm-burger-bars-hover{background:#40e0d0}.bm-cross-button{height:24px;width:24px}.bm-cross{background:#bdc3c7}.bm-menu-wrap{position:fixed;height:100%}.bm-menu{background:rgba(0,0,0,.9);padding:2.5em 1.5em 0;font-size:1.15em}.bm-morph-shape{fill:#000}.bm-item-list{color:#b8b7ad;padding:.8em}.bm-item{display:inline-block}.bm-overlay{background:#000}.menu-item{color:#fff;font-size:1.5rem;padding:1.5rem;text-align:center}.menu-item:hover{color:#fff;text-decoration:underline}@media only screen and (min-width: 1315px){.bm-item{display:none}.bm-item:hover{display:none}.bm-burger-button{display:none}.bm-burger-bars{display:none}.bm-item{display:none}.bm-cross-button{display:none}.bm-cross-button{display:none}.bm-cross-button{display:none}.bm-cross-button{display:none}}
.navbar{width:100%;display:flex;padding:20px;font-size:20px;display:flex;font-family:"Varela Round",sans-serif;justify-content:space-evenly;overflow-y:hidden}.navbar a{color:#fff;font-size:20px}.navbar a::after{content:"";display:block;width:0;height:2px;background:red;transition:width .3s}.navbar a:hover::after{width:100%;text-decoration:none}.navbar *{text-decoration:none}.navbar .home{display:flex;height:33px;width:100%;font-weight:1000;border-radius:10px;cursor:pointer;font-size:26px;overflow-y:hidden}.navbar .right{display:flex;width:540px;justify-content:space-between;align-items:center;margin-left:40%;background-color:transparent}.navbar .right #item{cursor:pointer;align-items:center;justify-content:center;height:35px;text-decoration:none;color:#fff}@media only screen and (max-width: 1315px){.navbar{display:none}.main-contact{margin-top:70px}}
.fp-container{position:fixed;width:100%;height:100%;top:0;left:0;background-color:#fff;z-index:1000;display:flex;flex-direction:column;justify-content:center;align-items:center}.fp-container .msg-container{text-align:center;align-items:center;justify-content:center}.fp-container .msg-container span{font-weight:500;font-size:1.8rem;color:#fff}.fp-container .msg-container .fp-loader{top:30%;left:48%;z-index:1000;position:absolute}
/* Import Pretty Checkboxes*/
/* Import Varela Round from Google Fonts*/

/*Variables defined*/
:root {
  --main-color: coral;
  --text-color: #444;
  --formcolor: white;
  --button-color: hsl(356, 90%, 64%);
}


.contain-list {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  text-decoration: none;
}

.main-list {
  text-align: center;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
}
.list-title {
  font-size: 1.4rem;
  color: white;
  font-weight: 600;
  text-decoration: none;
  padding: 0.5rem;
}

.list-diff {
  text-align: right;
  color: white;
  padding: 0.2rem;
  font-size: 1rem;
}

.list-org {
  text-align: center;
  color: white;
  padding: 0.8rem 0.2rem;
}

.org-name {
  font-size: 1.1rem;
  font-weight: 500;
}
.list-about {
  text-align: justify;
  color: white;
  font-size: 1rem;
  padding: 1rem;
  font-family: Georgia, 'Times New Roman', Times, serif;

}

.list-rating {
  text-align: left;
  color: white;
  font-size: 1.2rem;
  padding: 0.5rem;
  position: absolute;
  bottom: 4.5rem;
  left: 1rem;
  margin-top: 1rem;
}
.flip {
  display: inline-block;
}
.back {
  background-color: rgb(6, 15, 63, 0.8);
  width: 30rem;
  padding: 0.5rem;
  margin: 2.5rem;
  display: inline-block;
  color: white;
  overflow-y: hidden;
  height: 85%;
  overflow-y: hidden;
  
}
.card {
  width: 35rem;
  background-color: rgb(6, 15, 63, 0.8);
  margin: 2.5rem;
  padding: 0.5rem;
  display: inline-block;
  overflow-y: hidden;
  border-radius: 5px;
}

.list-heading {
  text-align: justify;
  padding: 1rem;
  color: white;
  font-size: 2.8rem;
  font-weight: 400;
  font-family: 'Varela', sans-serif;
}

.list-text{
  text-align: center;
  color: white;
  font-size: 1.8rem;
  font-weight: 500;
  padding: 2rem 3rem;
  margin-top: 0.5rem;
}
.list-subtext{
  text-align: justify;
  font-size: 1.3rem;
  padding: 1rem 5rem;
  margin-top: 0.5rem;

}

#front-toggle {
  text-align: center;
  background-color: rgb(64, 224, 208, 0.6);
  border: none;
  padding: 0.7rem 1rem;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  position: absolute;
  bottom: 1rem;
  left: calc(50% - 5rem);
  width: 10rem;
}
#back-toggle {
  text-align: center;
  background-color: rgb(64, 224, 208, 0.6);
  border: none;
  padding: 0.7rem 1rem;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  position: absolute;
  bottom: 5rem;
  left: calc(50% - 5rem);
  width: 10rem;
}

ul {
  text-align: center;
}
.c-list {
  font-size: 1.1rem;
  padding: 15px 0px;
}
.back-heading {
  text-align: center;
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 1.3rem 1rem;
}
a{
  color: white;
}
#link:hover{
  text-decoration: underline;
  color: white;
}

@media only screen and (max-width: 1220px) {
  .list-text {
    margin-top: 60px;
  }
  .back button {
    overflow-y: hidden;
  }
}
@media only screen and (max-width: 650px) {
  .back {
    width: 30rem;
    overflow: hidden;
  }
.list-text{
font-size: 1.6rem;
}
.list-subtext{
  font-size: 1.1rem;
}
}
@media only screen and (max-width: 520px) {
  .card {
    width: 30rem;
  }
.flippy-card, .flippy-back{
    overflow-y: hidden;
  }
  

  .back {
    width: 25rem;
    margin-left: 0px;
  }
  .list-heading {
    font-size: 1.5rem;
  }
  .list-rating {
    position: static;
    display: block;
  }
  #front-toggle {
    position: static;
    margin: 1.2rem;
    margin-left: calc(50% - 10rem);
  }
  #back-toggle{
    margin: 1.5rem 0rem;
  }
.list-text{
font-size: 1.5rem;
}
.list-subtext{
  font-size: 0.9rem;
  padding: 2rem;
}

}
@media only screen and (max-width: 420px) {
  .list-about {
    font-size: 0.9rem;
  }
  .card {
    width: 350px;
  }
  .back {
    width: 20rem;
  }
  .list-heading {
    font-size: 1.2rem;
  }
}


.list-heading{
  
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-weight: 800;
visibility: hidden;

}
.c-list{
  font-family: Georgia, 'Times New Roman', Times, serif;
}
.us{top:100vh;left:0;height:100%;width:100%;color:var(--text-color);font-family:"Varela Round",sans-serif;font-size:1.1rem;font-weight:bold;color:#fff;position:"absolute";display:flex;flex-direction:column;align-items:center;justify-content:center;padding:10px}.us .name{margin-top:70px;font-size:22px;text-align:center}.us .content1{margin-top:0px;width:680px;height:600px}.us .content1 .container{font-size:24px;margin-top:30px;text-align:center;display:flex}.us .content1 .aboutus{font-size:30px;text-align:center}@media only screen and (max-width: 773px){.us .content1{width:400px}.us .content1 .container{font-size:16px;padding:1rem}.us .content1 .aboutus{font-size:25px}}.front{top:0;left:0;height:100%;width:100%;color:var(--text-color);font-family:"Varela Round",sans-serif;font-size:1.1rem;font-weight:bold;color:#fff;position:"absolute";display:flex;flex-direction:column;align-items:center}.front .content{margin-top:80px;width:680px;height:500px;background-image:url(/static/media/DG_logo_1.ccc2352e.png);background-size:100% 100%;background-repeat:no-repeat;background-position:center}.front .navbar{width:100%;display:flex;padding:20px;font-size:20px;display:flex;font-family:"Varela Round",sans-serif;justify-content:space-evenly}.front .navbar a{color:#fff;font-size:20px}.front .navbar a::after{content:"";display:block;width:0;height:2px;background:red;transition:width .3s}.front .navbar a:hover::after{width:100%}.front .navbar .home{display:flex;height:33px;width:100%;font-weight:1000;border-radius:10px;cursor:pointer;font-size:26px;overflow-y:hidden}.front .navbar .right{display:flex;width:540px;justify-content:space-between;align-items:center;margin-left:40%;background-color:transparent}.front .navbar .right .item{cursor:pointer;align-items:center;justify-content:center;height:35px}@media only screen and (max-width: 1315px){.front .navbar{display:none}}
.contain-contact {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.main-contact {
  width: 450px;
  background-color: rgba(148, 143, 171, 0.3);
  border-radius: 10px;
  color: white;
  padding: 10px;
  margin-left: calc(50% - 225px);
}

.input-contact {
  width: 90%;
  margin: 0.8rem;
  padding: 0.5rem;
  color: white;
  background-color: transparent;
  border-radius: 15px;
  border: 2px solid gray;
}
::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.8;
}
:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.8;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.8;
}

.heading {
  text-align: center;
  overflow-y: hidden;
  font-size: 2rem;
  overflow-y: hidden;
}
.heading,
.heading p,
.heading h1 {
  overflow-y: hidden;
}
.heading p {
  font-size: 1.5rem;
  overflow-y: hidden;
}

.form-contact {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  width: 90%;
  border-radius: 15px;
  background-color: transparent;
  border: 2px solid gray;
  padding: 0.5rem;
  margin: 0.8rem;
}
#submit {
  background-color: transparent;
  border: 1.5px solid red;
  transition: 0.5s;
}
#submit:hover {
  background-color: red;
  color: white;
}

.name {
  padding: 1.5rem;
}

@media only screen and (max-width: 520px) {
  .main-contact {
    width: 360px;
    margin-left: calc(50% - 180px);
    margin-top: 70px;
  }
}
@media only screen and (max-width: 380px) {
  .main-contact {
    width: 340px;
    margin-left: calc(50% - 170px);
    margin-top: 70px;
  }
}

@media only screen and (max-width: 1315px) {
  .main {
    justify-content: center;
    margin-top: 70px;
  }
}

.contain {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
.header {
  color: white;
  text-align: center;
  font-size: 3rem;
  padding: 1rem;
  width: 100%;
  font-weight: 600;
  z-index: 1;
}

.main {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.card {
  width: 30rem;
  background-color: rgb(6, 15, 63, 0.8);
  margin: 2.5rem;
  padding: 0.5rem;
  display: inline-block;
  min-height: 380px;
}
.image {
  text-align: center;
  padding: 10px;
}
.profile-img {
  width: 8rem;
  height: 8rem;
  text-align: center;
}
.name {
  text-align: center;
  color: white;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0px;
}

.quote {
  text-align: justify;
  color: white;
  font-size: 1rem;
  margin: 1rem;
}

.social {
  text-align: center;
  margin: 0.5rem 0rem;
}
.social a {
  padding: 0rem 1rem;
}

@media only screen and (max-width: 660px) {
  .card {
    width: 450px;
    margin: 1rem auto;
  }
}

@media only screen and (max-width: 510px) {
  .card {
    width: 400px;
  }
}

@media only screen and (max-width: 430px) {
  .card {
    width: 360px;
  }
}

@media only screen and (max-width: 380px) {
  .card {
    width: 340px;
  }
}

.contain-how{
    width: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  
}

.main-how {
    width: 80%;
    background-color: rgba(148, 143, 171, 0.3);
    border-radius: 10px;
    color: white;
    padding: 10px;
    margin-left: 10%;
    min-height: 50vh;
  }


.how-list{
    color: white;
    padding: 2rem;
    font-size: 1.3rem;
    list-style-position: inside;
} 
.how-list li{
    padding: 1rem 0rem;
    text-align: justify;
} 
@media only screen and (max-width: 1330px) {
.main-how{
    margin-top: 70px;
}
}
@media only screen and (max-width: 650px) {
    .how-list{
        font-size: 1.1rem;
    }
}
@media only screen and (max-width: 520px) {

.how-list li{
padding: 0.6rem;
}
.main-how{
    width: 90%;
    margin-left: 5%;
}
.how-list{
    padding: 1rem;
}
}
@media only screen and (max-width: 420px) {
.how-list{
    padding: 0.2rem;
    font-size: 1rem;
}


}
